// RoutingWrapper.js
import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import ProductDetails from '../components/ProductDetails';

const ProductDetailsWrapper = (props) => {
  const params = useParams();
  const navigate = useNavigate();

  return <ProductDetails {...props} params={params} navigate={navigate} />;
};

export default ProductDetailsWrapper;