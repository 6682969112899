import React, { Component } from 'react';
import { withApollo } from '@apollo/client/react/hoc';
import { GET_PRODUCT_BY_ID } from '../queries';
import parse from 'html-react-parser';
import './ProductDetails.css';
import { generateCartItemId } from '../utils';

class ProductDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedImage: null,
      selectedSize: null,
      selectedCapacity: null,
      selectedColor: null,
      usbPortsChecked: false,
      touchIdChecked: false,
      currentIndex: 0,
    };
  }

  componentDidMount() {
    const { id } = this.props.params;
    this.props.client
      .query({
        query: GET_PRODUCT_BY_ID,
        variables: { id },
      })
      .then(result => {
        this.setState({ product: result.data.getProductById });
      })
      .catch(error => {
        this.setState({ error: error.message });
      });
  }

  handleImageClick = (imgUrl) => {
    const { images } = this.state.product;
    const index = images.findIndex(img => img.url === imgUrl);
    this.setState({ selectedImage: imgUrl, currentIndex: index });
  };

  handleAddToCart = () => {
    const { product } = this.state;
    const { onAddToCart } = this.props;

    if (!product.in_stock) return;

    const selectedAttributes = {};
    const {
      selectedSize,
      selectedCapacity,
      selectedColor,
      usbPortsChecked,
      touchIdChecked,
    } = this.state;

    if (selectedSize) selectedAttributes['Size'] = selectedSize;
    if (selectedCapacity) selectedAttributes['Capacity'] = selectedCapacity;
    if (selectedColor) selectedAttributes['Color'] = selectedColor;
    if (usbPortsChecked !== false) selectedAttributes['With USB 3 ports'] = usbPortsChecked ? 'Included' : null;
    if (touchIdChecked !== false) selectedAttributes['Touch ID in keyboard'] = touchIdChecked ? 'Included' : null;

    const allOptions = {};

    // Populate allOptions similarly as in the functional component
    const sizeAttributes = product.attributes.filter(attr => attr.attribute_name === 'Size');
    const colorAttributes = product.attributes.filter(attr => attr.attribute_name === 'Color');
    const capacityAttributes = product.attributes.filter(attr => attr.attribute_name === 'Capacity');
    const usbPortsAttribute = product.attributes.find(attr => attr.attribute_name === 'With USB 3 ports');
    const touchIdAttribute = product.attributes.find(attr => attr.attribute_name === 'Touch ID in keyboard');

    sizeAttributes.forEach(attr => {
      allOptions['Size'] = sizeAttributes.map(size => size.attribute_value);
    });

    colorAttributes.forEach(attr => {
      allOptions['Color'] = colorAttributes.map(color => color.attribute_value);
    });

    capacityAttributes.forEach(attr => {
      allOptions['Capacity'] = capacityAttributes.map(capacity => capacity.attribute_value);
    });

    if (usbPortsAttribute) {
      allOptions['With USB 3 ports'] = usbPortsChecked ? ['Included'] : ['Not included'];
    }

    if (touchIdAttribute) {
      allOptions['Touch ID in keyboard'] = touchIdChecked ? ['Included'] : ['Not included'];
    }

    const newCartItem = {
      id: generateCartItemId(product.id, selectedAttributes),
      product_id: product.id,
      name: product.name,
      price: product.prices[0]?.amount || '0.00',
      imageUrl: product.images[0].url,
      attributes: selectedAttributes,
      allOptions: allOptions,
    };

    const storedCartItems = JSON.parse(localStorage.getItem('cartItems')) || [];
    const updatedCartItems = [...storedCartItems, newCartItem];

    localStorage.setItem('cartItems', JSON.stringify(updatedCartItems));
    onAddToCart(newCartItem);
  };

  handleCheckboxChange = (attributeName) => {
    if (attributeName === 'With USB 3 ports') {
      this.setState(prevState => ({ usbPortsChecked: !prevState.usbPortsChecked }));
    } else if (attributeName === 'Touch ID in keyboard') {
      this.setState(prevState => ({ touchIdChecked: !prevState.touchIdChecked }));
    }
  };

  goToPreviousImage = () => {
    this.setState(prevState => ({
      currentIndex: prevState.currentIndex > 0 ? prevState.currentIndex - 1 : this.state.product.images.length - 1,
    }));
  };

  goToNextImage = () => {
    this.setState(prevState => ({
      currentIndex: prevState.currentIndex < this.state.product.images.length - 1 ? prevState.currentIndex + 1 : 0,
    }));
  };

  render() {
    const { product, error } = this.state;
    const { onAddToCart } = this.props;

    if (error) return <p>Error: {error}</p>;
    if (!product) return <p>Loading...</p>;

    const {
      name = 'No Name',
      description = 'No description available',
      images = [{ url: '/default-image.png' }],
      attributes = [],
      prices = [{ amount: '0.00' }],
      in_stock = true,
    } = product;

    const sizeAttributes = attributes.filter(attr => attr.attribute_name === 'Size');
    const colorAttributes = attributes.filter(attr => attr.attribute_name === 'Color');
    const capacityAttributes = attributes.filter(attr => attr.attribute_name === 'Capacity');
    const usbPortsAttribute = attributes.find(attr => attr.attribute_name === 'With USB 3 ports');
    const touchIdAttribute = attributes.find(attr => attr.attribute_name === 'Touch ID in keyboard');

    const isAddToCartDisabled = !in_stock || (sizeAttributes.length > 0 && !this.state.selectedSize) ||
      (colorAttributes.length > 0 && !this.state.selectedColor) || (capacityAttributes.length > 0 && !this.state.selectedCapacity);

    return (
      <div className="product-details-container">
        <div className="product-details">
          <div data-testid="product-gallery" className="product-gallery">
            <div className="thumbnail-gallery">
              {images.map((img, index) => (
                <img
                  key={img.url}
                  src={img.url}
                  alt={name}
                  className={`thumbnail ${index === this.state.currentIndex ? 'active' : ''}`}
                  onClick={() => this.handleImageClick(img.url)}
                />
              ))}
            </div>
            <div className="carousel-container">
              <button className="carousel-button left" onClick={this.goToPreviousImage}>‹</button>
              <div className="carousel-wrapper">
                <div className="carousel">
                  {images.length > 0 ? (
                    images.map((img, index) => (
                      <img
                        key={img.url}
                        src={img.url}
                        alt={name}
                        className={`carousel-image ${index === this.state.currentIndex ? 'visible' : ''}`}
                        onClick={() => this.handleImageClick(img.url)}
                      />
                    ))
                  ) : (
                    <p>No images available</p>
                  )}
                </div>
              </div>
              <button className="carousel-button right" onClick={this.goToNextImage}>›</button>
            </div>
          </div>
          <div className="product-information">
            <h1>{name}</h1>

            {sizeAttributes.length > 0 && (
              <div data-testid="product-attribute-size" className="product-sizes">
                <strong>SIZE:</strong>
                <div className="size-options">
                  {sizeAttributes.map(size => (
                    <button
                      key={size.attribute_value}
                      className={this.state.selectedSize === size.attribute_value ? 'selected-option' : ''}
                      data-testid={
                        "product-attribute-size-" + size.attribute_value
                      }
                      onClick={() => this.setState({ selectedSize: size.attribute_value })}
                    >
                      {size.attribute_value}
                    </button>
                  ))}
                </div>
              </div>
            )}

            {colorAttributes.length > 0 && (
              <div data-testid="product-attribute-color" className="product-colors">
                <strong>COLOR:</strong>
                <div className="color-options">
                  {colorAttributes.map(color => (
                    <button
                      key={color.attribute_value}
                      className={this.state.selectedColor === color.attribute_value ? 'selected-color' : ''}
                      data-testid={
                        "product-attribute-color-" + color.attribute_value
                      }
                      onClick={() => this.setState({ selectedColor: color.attribute_value })}
                    >
                      <div
                        className="color-swatch"
                        style={{ backgroundColor: color.attribute_value }}
                      ></div>
                    </button>
                  ))}
                </div>
              </div>
            )}

            {capacityAttributes.length > 0 && (
              <div data-testid="product-attribute-capacity" className="product-capacity">
                <strong>CAPACITY:</strong>
                <div className="capacity-options">
                  {capacityAttributes.map(capacity => (
                    <button
                      key={capacity.attribute_value}
                      className={this.state.selectedCapacity === capacity.attribute_value ? 'selected-option' : ''}
                      data-testid={
                        "product-attribute-capacity-" + capacity.attribute_value
                      }
                      onClick={() => this.setState({ selectedCapacity: capacity.attribute_value })}
                    >
                      {capacity.attribute_value}
                    </button>
                  ))}
                </div>
              </div>
            )}

            {usbPortsAttribute && (
              <div data-testid="product-attribute-usb-ports" className="product-usb-ports">
                <label>
                  <input
                    type="checkbox"
                    checked={this.state.usbPortsChecked}
                    onChange={() => this.handleCheckboxChange('With USB 3 ports')}
                  />
                  With USB 3 ports
                </label>
              </div>
            )}

            {touchIdAttribute && (
              <div data-testid="product-attribute-touch-id" className="product-touch-id">
                <label>
                  <input
                    type="checkbox"
                    checked={this.state.touchIdChecked}
                    onChange={() => this.handleCheckboxChange('Touch ID in keyboard')}
                  />
                  Touch ID in keyboard
                </label>
              </div>
            )}
            <div className="product-price">
              <strong>PRICE:</strong>
              <span className="price-amount">${prices[0]?.amount || '0.00'}</span>
            </div>

            <button
              data-testid="add-to-cart"
              className={`add-to-cart-button ${isAddToCartDisabled ? 'disabled' : ''}`}
              onClick={this.handleAddToCart}
              disabled={isAddToCartDisabled}
            >
              ADD TO CART
            </button>
            <div data-testid="product-description" className="product-description">
              {parse(description)}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withApollo(ProductDetails);
