import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withApollo } from '@apollo/client/react/hoc';
import { CREATE_ORDER } from '../mutations';
import './CartOverlay.css';

class CartOverlay extends Component {
  constructor(props) {
    super(props);
    this.state = {
      successMessage: '',
      loading: false,
      error: null
    };
  }

  handlePlaceOrder = async () => {
    const { items, onClearCart } = this.props;
    const { successMessage } = this.state;

    this.setState({ loading: true });

    try {
      const { data } = await this.props.client.mutate({
        mutation: CREATE_ORDER,
        variables: {
          total_amount: parseFloat(this.calculateTotalAmount()), // Correct argument name
          items: items.map(item => ({
            product_id: item.product_id, // Use correct field name
            quantity: item.quantity || 1,
            price: parseFloat(item.price) || 0,
            attributes: Object.entries(item.attributes || {}).map(([name, value]) => ({
              attribute_name: name, // Adjusted to match the schema
              attribute_value: value
            }))
          })),
        },
      });

      console.log("Order created:", data.createOrder);
      this.setState({ successMessage: "Order placed successfully!" }); // Set success message
      onClearCart();
    } catch (err) {
      console.error("Error creating order:", err);
      this.setState({ error: err.message });
    } finally {
      this.setState({ loading: false });
    }
  };

  calculateTotalAmount = () => {
    const { items } = this.props;
    return items.reduce((total, item) => {
      const price = parseFloat(item.price) || 0;
      const quantity = parseInt(item.quantity, 10) || 1;
      return total + price * quantity;
    }, 0).toFixed(2);
  };

  render() {
    const { items, onRemoveItem, onIncreaseQuantity, onDecreaseQuantity } = this.props;
    const { successMessage, loading, error } = this.state;

    if (!Array.isArray(items)) {
      console.error("Invalid items prop:", items);
      return <p className="cart-overlay-error">There was an error loading your cart.</p>;
    }

    const totalAmount = this.calculateTotalAmount();
    const totalItems = items.reduce((count, item) => count + (item.quantity || 0), 0);
    const isEmpty = items.length === 0;

    return (
      <div data-testid="cart-overlay" className="cart-overlay">
        <div className="cart-overlay-header">
          <h2 className="cart-overlay-title">My Bag</h2>
          <span className="cart-quantity">{totalItems} {totalItems === 1 ? 'item' : 'items'}</span>
        </div>
        {isEmpty ? (
          <p className="cart-overlay-empty">Your cart is empty</p>
        ) : (
          <ul className="cart-overlay-list">
            {items.map((item, index) => {
              const imageUrl = item.imageUrl ?? '/default-image.png';
              const name = item.name ?? 'No Name';
              const price = parseFloat(item.price) || 0;
              const quantity = parseInt(item.quantity, 10) || 1;
              const attributeOptions = item.allOptions || {};
              const selectedAttributes = item.attributes || {};

              return (
                <li key={index} className="cart-overlay-item">
                  <div className="cart-overlay-item-details">
                    <span className="cart-overlay-item-name">{name}</span>
                    <span className="cart-overlay-item-price">${(price * quantity).toFixed(2)}</span>
                    {Object.keys(attributeOptions).length > 0 && (
                      <div className="cart-overlay-item-attributes">
                        {Object.entries(attributeOptions).map(([attrName, options]) => (
                          <div
                          data-testid={`cart-item-attribute-${attrName.toLowerCase()}`}
                           key={attrName} className="cart-overlay-item-attribute">
                            <strong>{attrName}:</strong>
                            <ul className="cart-overlay-attribute-options">
                              {options.map((optionValue, idx) => (
                                <li
                                  key={idx}
                                  data-testid={`cart-item-attribute-${attrName.toLowerCase()}-${optionValue.toLowerCase()}${selectedAttributes[attrName] === optionValue ? '-selected' : ''}`}
                                  className={`cart-overlay-attribute-option ${attrName.toLowerCase()}-option ${selectedAttributes[attrName] === optionValue ? 'selected-option' : ''}`}
                                  style={attrName.toLowerCase() === 'color' ? { backgroundColor: optionValue } : {}}
                                >
                                  {attrName.toLowerCase() === 'color' ? '' : optionValue}
                                </li>
                              ))}
                            </ul>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                  <div className="cart-overlay-quantity-controls">
                    <button
                      data-testid="cart-item-amount-increase"
                      className="cart-overlay-increase-button"
                      onClick={() => onIncreaseQuantity(item.id, selectedAttributes)}
                    >
                      +
                    </button>
                    <span data-testid="cart-item-amount" className="cart-overlay-quantity">{quantity}</span>
                    <button
                      data-testid="cart-item-amount-decrease"
                      className="cart-overlay-decrease-button"
                      onClick={() => {
                        if (quantity === 1) {
                          onRemoveItem(item.id, selectedAttributes);
                        } else {
                          onDecreaseQuantity(item.id, selectedAttributes);
                        }
                      }}
                    >
                      -
                    </button>
                  </div>
                  <img src={imageUrl} alt={name} className="cart-overlay-item-image" />
                </li>
              );
            })}
          </ul>
        )}
        <div className="cart-overlay-footer">
          <div className="cart-overlay-total">
            <span className="cart-overlay-total-label">Total:</span>
            <span data-testid="cart-total" className="cart-overlay-total-amount">${totalAmount}</span>
          </div>
          <button
            className={`cart-overlay-checkout-button ${isEmpty ? 'disabled' : ''}`}
            disabled={isEmpty || loading}
            onClick={this.handlePlaceOrder}
          >
            {loading ? 'Processing...' : 'PLACE ORDER'}
          </button>
          {error && <p className="cart-overlay-error">Failed to place order: {error}</p>}
          {successMessage && <p className="cart-overlay-success">{successMessage}</p>} {/* Success message */}
        </div>
      </div>
    );
  }
}

CartOverlay.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string,
    price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    quantity: PropTypes.number,
    imageUrl: PropTypes.string,
    attributes: PropTypes.objectOf(PropTypes.string),
    allOptions: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),
  })),
  onRemoveItem: PropTypes.func.isRequired,
  onIncreaseQuantity: PropTypes.func.isRequired,
  onDecreaseQuantity: PropTypes.func.isRequired,
  onClearCart: PropTypes.func.isRequired,
  client: PropTypes.object.isRequired
};

export default withApollo(CartOverlay);
