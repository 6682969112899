import React, { Component, createContext } from 'react';
import { withApollo } from '@apollo/client/react/hoc';
import { GET_CATEGORIES } from '../queries';

const CategoriesContext = createContext();

export const useCategories = () => {
  return React.useContext(CategoriesContext);
};

class CategoriesProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: [],
      loading: true,
      error: null
    };
  }

  componentDidMount() {
    this.fetchCategories();
  }

  fetchCategories = async () => {
    const { client } = this.props;

    if (!client) {
      console.error("Apollo Client is not available.");
      return;
    }

    try {
      const { data } = await client.query({
        query: GET_CATEGORIES
      });

      this.setState({
        categories: data.getCategories,
        loading: false,
        error: null
      });
    } catch (error) {
      this.setState({
        loading: false,
        error
      });
    }
  };

  render() {
    const { children } = this.props;
    const { categories, loading, error } = this.state;

    return (
      <CategoriesContext.Provider value={{ categories, loading, error }}>
        {children}
      </CategoriesContext.Provider>
    );
  }
}

export { CategoriesProvider };
export default withApollo(CategoriesProvider);