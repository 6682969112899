import React from 'react';
import { useParams } from 'react-router-dom';
import CategoryPage from '../components/CategoryPage';

const CategoryPageWrapper = (props) => {
  const params = useParams();

  return <CategoryPage {...props} categoryName={params.categoryName} />;
};

export default CategoryPageWrapper;
