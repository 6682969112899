import { gql } from '@apollo/client';

// GraphQL query to get categories
export const GET_CATEGORIES = gql`
  query GetCategories {
    getCategories {
      id
      name
    }
  }
`;

export const GET_PRODUCTS_BY_CATEGORY = gql`
  query GetProductsByCategory($category: String!) {
    getProductsByCategory(category: $category) {
      id
      name
      prices {
        amount
        currency_label
      }
      in_stock
      description
      images {
        url
      }
      attributes {
        attribute_name
        attribute_value
      }
    }
  }
`;

export const GET_PRODUCT_BY_ID = gql`
  query GetProductById($id: String!) {
    getProductById(id: $id) {
      id
      name
      in_stock
      description
      category {
        id
        name
      }
      brand
      images {
        url
      }
      attributes {
        attribute_name
        attribute_value
      }
      prices {
        amount
        currency_label
      }
    }
  }
`;

