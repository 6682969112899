export function generateCartItemId(productId, selectedAttributes) {
  const sortedAttributes = Object.keys(selectedAttributes)
    .sort()
    .reduce((acc, key) => {
      acc[key] = selectedAttributes[key];
      return acc;
    }, {});

  const attributesString = JSON.stringify(sortedAttributes);

  return `${productId}-${attributesString}`;
}