import { gql } from '@apollo/client';

export const CREATE_ORDER = gql`
  mutation CreateOrder($total_amount: Float!, $items: [OrderItemInput!]!) {
    createOrder(total_amount: $total_amount, items: $items) {
      id
      total_amount
      created_at
      items {
        id
        product_id
        quantity
        price
      }
    }
  }
`;
