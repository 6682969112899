import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import client from './apolloClient';
import ProductDetailsWrapper from './wrappers/ProductDetailsWrapper';
import CategoryPageWrapper from './wrappers/CategoryPageWrapper';
import Header from './components/Header';
import CartOverlay from './components/CartOverlay';
import { CategoriesProvider } from './contexts/CategoriesContext';
import './App.css';

// Class component for AppContent
class AppContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCartOpen: false,
      cartItems: [],
    };
  }

  componentDidMount() {
    const storedCartItems = JSON.parse(localStorage.getItem('cartItems')) || [];
    this.setState({ cartItems: storedCartItems });
  }

  toggleCart = () => {
    this.setState(prevState => ({ isCartOpen: !prevState.isCartOpen }));
  };

  closeCart = () => {
    this.setState({ isCartOpen: false });
  };

  handleAddToCart = (product) => {
    this.setState(prevState => {
      const existingItem = prevState.cartItems.find(item => item.id === product.id);
      const updatedItems = existingItem
        ? prevState.cartItems.map(item => 
            item.id === product.id 
              ? { ...item, quantity: item.quantity + 1 } 
              : item
          )
        : [...prevState.cartItems, { ...product, quantity: 1 }];
      
      localStorage.setItem('cartItems', JSON.stringify(updatedItems));
      return { cartItems: updatedItems, isCartOpen: true };
    });
  };

  handleRemoveItem = (itemId) => {
    this.setState(prevState => {
      const updatedItems = prevState.cartItems.filter(item => item.id !== itemId);
      localStorage.setItem('cartItems', JSON.stringify(updatedItems));
      return { cartItems: updatedItems };
    });
  };

  handleClearCart = () => {
    const updatedItems = [];
    this.setState({ cartItems: updatedItems });
    localStorage.setItem('cartItems', JSON.stringify(updatedItems));
  };

  handleIncreaseQuantity = (itemId) => {
    this.setState(prevState => {
      const updatedItems = prevState.cartItems.map(item => 
        item.id === itemId 
          ? { ...item, quantity: item.quantity + 1 } 
          : item
      );
      localStorage.setItem('cartItems', JSON.stringify(updatedItems));
      return { cartItems: updatedItems };
    });
  };

  handleDecreaseQuantity = (itemId) => {
    this.setState(prevState => {
      const updatedItems = prevState.cartItems.map(item => 
        item.id === itemId && item.quantity > 1 
          ? { ...item, quantity: item.quantity - 1 } 
          : item
      );
      localStorage.setItem('cartItems', JSON.stringify(updatedItems));
      return { cartItems: updatedItems };
    });
  };

  render() {
    const { categories = [], loading, error } = this.props; // Default categories to an empty array

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error.message}</div>;

    return (
      <div className="App">
        <Header 
          toggleCart={this.toggleCart} 
          cartItems={this.state.cartItems} 
          onClose={this.closeCart} 
        />
        {this.state.isCartOpen && (
          <CartOverlay
            items={this.state.cartItems}
            onClose={this.closeCart}
            onRemoveItem={this.handleRemoveItem}
            onIncreaseQuantity={this.handleIncreaseQuantity}
            onDecreaseQuantity={this.handleDecreaseQuantity}
            onClearCart={this.handleClearCart}
          />
        )}
        <Routes>
          <Route 
            path="/" 
            element={<Navigate to={`/${categories.length > 0 ? categories[0].name.toLowerCase() : 'all'}`} />} 
          />
          <Route 
            path="/products/:id" 
            element={<ProductDetailsWrapper onAddToCart={this.handleAddToCart} />} 
          />
          <Route 
            path="/:categoryName" 
            element={<CategoryPageWrapper setCartOpen={this.toggleCart} isCartOpen={this.state.isCartOpen} onAddToCart={this.handleAddToCart} />} 
          />
        </Routes>
      </div>
    );
  }
}

// Class component for App
class App extends Component {
  render() {
    return (
      <ApolloProvider client={client}>
        <CategoriesProvider>
          <Router>
            <AppContent />
          </Router>
        </CategoriesProvider>
      </ApolloProvider>
    );
  }
}

export default App;
