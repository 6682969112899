import React, { Component } from 'react';
import { withApollo } from '@apollo/client/react/hoc';
import { GET_PRODUCTS_BY_CATEGORY } from '../queries';
import HomePage from './HomePage';
import './HomePage.css';

class CategoryPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      error: null,
      products: [],
    };
  }

  componentDidMount() {
    this.fetchProducts();
  }

  componentDidUpdate(prevProps) {
    // If the category changes, fetch products again
    if (prevProps.categoryName !== this.props.categoryName) {
      this.fetchProducts();
    }
  }

  fetchProducts = async () => {
    const { client, categoryName } = this.props;
    const category = categoryName === 'all' ? '' : categoryName;

    this.setState({ loading: true, error: null });

    try {
      const { data } = await client.query({
        query: GET_PRODUCTS_BY_CATEGORY,
        variables: { category },
      });
      this.setState({ products: data.getProductsByCategory, loading: false });
    } catch (error) {
      this.setState({ error, loading: false });
    }
  };

  render() {
    const { loading, error, products } = this.state;
    const { isCartOpen, onAddToCart, setCartOpen, categoryName } = this.props;

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error.message}</p>;

    return (
      <div className={`category-page ${isCartOpen ? 'greyed-out' : ''}`}>
        <h1>{categoryName.toUpperCase()}</h1>
        <HomePage setCartOpen={setCartOpen} onAddToCart={onAddToCart} products={products} />
      </div>
    );
  }
}

export default withApollo(CategoryPage);
