import React, { Component, createContext } from 'react';

const CartContext = createContext();

class CartProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cart: []
    };
  }

  addToCart = (product) => {
    this.setState(prevState => {
      const existingItemIndex = prevState.cart.findIndex(item => item.id === product.id);
      if (existingItemIndex >= 0) {
        // Update quantity if item already exists
        return {
          cart: prevState.cart.map((item, index) =>
            index === existingItemIndex
              ? { ...item, quantity: (item.quantity || 1) + (product.quantity || 1) }
              : item
          )
        };
      } else {
        // Add new item if it does not exist
        return {
          cart: [...prevState.cart, { ...product, quantity: product.quantity || 1 }]
        };
      }
    });
  };

  render() {
    return (
      <CartContext.Provider value={{ cart: this.state.cart, addToCart: this.addToCart }}>
        {this.props.children}
      </CartContext.Provider>
    );
  }
}

// const useCart = () => {
//   return React.useContext(CartContext);
// };

export { CartProvider };
