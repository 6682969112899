import React, { Component } from 'react';
import { withApollo } from '@apollo/client/react/hoc';
import { Link } from 'react-router-dom';
import { GET_CATEGORIES } from '../queries';
import './Header.css';

class Header extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      isMenuOpen: false,
      categories: [],
      loading: true,
      error: null,
      activeCategory: null,
    };
  }

  componentDidMount() {
    this.fetchCategories();
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  fetchCategories = async () => {
    const { client } = this.props;

    try {
      const { data } = await client.query({
        query: GET_CATEGORIES,
      });

      console.log(data.getCategories);
      this.setState({
        categories: data.getCategories,
        loading: false,
        activeCategory: data.getCategories.length > 0 ? data.getCategories[0].id : null,
      });
    } catch (error) {
      this.setState({
        loading: false,
        error,
      });
    }
  };

  handleResize = () => {
    if (window.innerWidth > 768) {
      this.setState({ isMenuOpen: false });
    }
  };

  handleCategoryClick = (category) => {
    this.setState({ activeCategory: category.id });
    this.props.onClose();
  };

  render() {
    const { toggleCart, cartItems } = this.props;
    const { categories, loading, error, activeCategory } = this.state;

    if (loading) return <div className="navbar">Loading...</div>;
    if (error) return <div className="navbar">Error: {error.message}</div>;

    const itemCount = cartItems.reduce((count, item) => count + item.quantity, 0);

    return (
      <nav className="navbar">
        <div className="navbar-left">
          <span className="site-title">My Store</span>
          <ul className="navbar-nav">
            {categories.map((category) => (
              <li onClick={() => this.handleCategoryClick(category)} key={category.id} className="nav-item">
                <Link
                  className="nav-link"
                  to={`/${category.name.toLowerCase()}`}
                  data-testid={
                    activeCategory === category.id ? 'active-category-link' : 'category-link'
                  }
                >
                  {category.name}
                </Link>
              </li>
            ))}
          </ul>

        </div>
        <div className="navbar-center">
          <img src="/brand-icon.svg" alt="Brandicon" className="center-icon" />
        </div>
        <div className="navbar-right">
          <div data-testid="cart-btn" className="cart-container" onClick={toggleCart}>
            <div className="empty-cart">
              <img src="/black-cart.svg" alt="Blackcart" className="cart-0" />
            </div>
            {itemCount > 0 && <div className="cart-badge">{itemCount}</div>}
          </div>
        </div>
      </nav>
    );
  }
}

export default withApollo(Header);
