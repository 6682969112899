import React, { Component } from 'react';
import './HomePage.css';
import { generateCartItemId } from '../utils';

class HomePage extends Component {
  handleCardClick = (product) => {
    window.location.href = `/products/${product.id}`;
  };

  handleAddToCartClick = (event, product) => {
    event.stopPropagation();

    const selectedAttributes = {};
    const allOptions = {};

    product.attributes.forEach(attr => {
      const attributeCopy = { ...attr };

      if (attributeCopy.attribute_value === '0') {
        return;
      }
      if (attributeCopy.attribute_value === '1') {
        attributeCopy.attribute_value = 'Included';
      }
      if (!allOptions[attributeCopy.attribute_name]) {
        allOptions[attributeCopy.attribute_name] = [];
      }
      allOptions[attributeCopy.attribute_name].push(attributeCopy.attribute_value);

      if (!selectedAttributes[attributeCopy.attribute_name]) {
        selectedAttributes[attributeCopy.attribute_name] = attributeCopy.attribute_value;
      }
    });

    const newCartItem = {
      id: generateCartItemId(product.id, selectedAttributes),
      product_id: product.id,
      name: product.name,
      price: product.prices[0]?.amount || '0.00',
      imageUrl: product.images[0]?.url || 'default.jpg',
      attributes: selectedAttributes,
      allOptions: allOptions,
    };

    const storedCartItems = JSON.parse(localStorage.getItem('cartItems')) || [];
    const updatedCartItems = [...storedCartItems, newCartItem];

    localStorage.setItem('cartItems', JSON.stringify(updatedCartItems));
    if (this.props.onAddToCart) {
      this.props.onAddToCart(newCartItem);
      this.props.setCartOpen(false);
    }
  };

  render() {
    const { products = [], isCartOpen, setCartOpen } = this.props;
    const productList = products;

    return (
      <div className={`home-page ${isCartOpen ? 'greyed-out' : ''}`}>
        <div className="product-grid">
          {productList.map(product => {
            const productNameKebabCase = product.name
              .toLowerCase()
              .replace(/\s+/g, '-')
              .replace(/[^\w\-]+/g, '')
              .replace(/--+/g, '-');
            return (
              <div
                key={product.id}
                data-testid={`product-${productNameKebabCase}`}
                className={`product-card ${!product.in_stock ? 'out-of-stock' : ''}`}
                onClick={() => this.handleCardClick(product)}
                style={{ cursor: 'pointer' }}
              >
                <div className="product-image-container">
                  <img
                    src={product.images[0]?.url || 'default.jpg'}
                    alt={product.name}
                    className="product-image"
                  />
                  {!product.in_stock && <div className="out-of-stock-overlay">Out Of Stock</div>}
                  {product.in_stock && (
                    <div
                      className="circle-icon"
                      onClick={(event) => this.handleAddToCartClick(event, product)}
                    >
                      <img
                        src="/surface1201-y3ap-200h.png"
                        alt="Surface1201"
                        className="surface-circle"
                      />
                      <img
                        src="/white-cart.svg"
                        alt="Whitecart"
                        className="empty-cart-icon"
                      />
                    </div>
                  )}
                </div>
                <div className="product-info">
                  <h2 className="product-title">{product.name}</h2>
                  <p className="product-price">
                    <span className="price-amount">${product.prices[0]?.amount}</span>
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
    
  }
}

export default HomePage;